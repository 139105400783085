import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3c66df30 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _57f36670 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _47208c62 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _3a60a872 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _1e2af026 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _44cded83 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _8dd5d15c = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _2134820f = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _515ec56b = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _099011c5 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _8a69fbe0 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _6058d8e6 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _3100ca16 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _59a00311 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _58f48aec = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _c7ac5214 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _69bcf672 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _3c66df30,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _57f36670,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _47208c62,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _3a60a872,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _1e2af026,
    name: "receipt"
  }, {
    path: "/search",
    component: _44cded83,
    name: "search"
  }, {
    path: "/search-booking",
    component: _8dd5d15c,
    name: "search-booking"
  }, {
    path: "/terms-and-conditions",
    component: _2134820f,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _515ec56b,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _099011c5,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _8a69fbe0,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _6058d8e6,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _3100ca16,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _59a00311,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/product",
    component: _58f48aec,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _c7ac5214,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _69bcf672,
    name: "index"
  }, {
    path: "/dedicated",
    component: _69bcf672,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-index"
  }, {
    path: "/dedicated/search",
    component: _44cded83,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-search"
  }, {
    path: "/dedicated/select-extras/product",
    component: _58f48aec,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-select-extras"
  }, {
    path: "/dedicated/manage-booking/modify",
    component: _6058d8e6,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-modify"
  }, {
    path: "/dedicated/receipt",
    component: _1e2af026,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-receipt"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
